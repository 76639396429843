<template>
    <div class="degreeDialog">
        <el-dialog :title="myTitle" width="80%"  :visible.sync="dialogVisible" :close-on-click-modal="false" style="height: 800px;">
            <el-form :model="degreeForm" :inline="true" ref="degreeDialogForm" :rules="degreeRules">

                <el-form-item label="名称"  prop="name" :label-width="formLabelWidth">
                    <el-input v-model="degreeForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型"  prop="degreeType" :label-width="formLabelWidth">
                    <el-select v-model="degreeForm.degreeType" filterable placeholder="请选择" @change="degreeTypeChange">
                        <el-option v-for="item in degreeTypeList" :key="item.name" :label="item.label" :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="课程" v-if="degreeForm.degreeType == 'KC'" prop="courseId" :label-width="formLabelWidth">
                    <el-select v-model="degreeForm.courseId" filterable placeholder="请选择">
                        <el-option v-for="item in courseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" icon="search" @click='addTarget'>添加目标</el-button>
                </el-form-item>
                <el-form-item>
                    <el-upload class="upload-demo" :multiple="false" :show-file-list="false"
                               accept="csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :http-request="httpRequest">
                        <el-button size="mini" type="success">平时成绩</el-button>
                    </el-upload>
                </el-form-item>

            </el-form>
            <el-descriptions style="margin-bottom: 20px;" title="已上传平时成绩" v-if="scoreTypeList.length > 0"  :column="1" border>
                <el-descriptions-item label="包含的分数项：">
                    <span v-for="item in scoreTypeList">{{item.seq + "、" +item.name}}，</span>
                </el-descriptions-item>
            </el-descriptions>

            <el-descriptions style="margin-bottom: 20px;" v-if="configContent!=null && configContent.list!=null && configContent.list.length > 0"  :column="1" border
            v-for="(item, index) in configContent.list">
                <template slot="title">

                    <div v-if="!item.isEdit">
                        <span >{{index+1}}、</span>
                        <span>{{item.name}}</span>
                        <el-button type="text" size="mini" icon="search" @click='setEditble(item, "isEdit", true)' style="margin-left: 30px;">更改</el-button>
                    </div>
                    <div v-else>
                        <span >{{index+1}}、</span>
                        <el-input v-model="item.name" autocomplete="off" style="width: 180px;"></el-input>
                        <el-button type="text" size="mini" icon="search" @click='setEditble(item, "isEdit", false)'>保存</el-button>
                    </div>
                </template>
                <template slot="extra">
                    <el-button type="primary" size="mini" @click="addExam(index)">添加试卷</el-button>
                    <el-button type="danger" size="mini" @click="deleteExam(index)">删除</el-button>
                </template>
                <el-descriptions-item v-for="(m2, i2) in item.list">
                    <template slot="label">
                        <el-row>
                            <el-col :span="15" style="line-height: 35px;">
                                <div v-if="!m2.isEdit">
                                    <span>{{m2.name}}</span>
                                    <el-button type="text" size="mini" icon="search" @click='setEditble(m2, "isEdit", true)' style="margin-left: 10px;">更改</el-button>
                                </div>
                                <div v-else>
                                    <el-input v-model="m2.name" autocomplete="off" style="width: 180px;"></el-input>
                                    <el-button type="text" size="mini" icon="search" @click='setEditble(m2, "isEdit", false)'>保存</el-button>
                                </div>
                            </el-col>

                            <el-col :span="9" style="line-height: 35px;">
                                <span>系数：</span>
                                <el-input v-model="m2.degreeValue" autocomplete="off" style="width: 60px;"></el-input>
                            </el-col>
                        </el-row>

                    </template>
                    <el-row style="min-width: 350px;">
                        <el-col :span="9" style="line-height: 35px;">
                            <el-radio-group v-model="m2.type" @change="typeChange(index, i2)">
                                <el-radio :label="0">考试</el-radio>
                                <el-radio :label="1">平时成绩</el-radio>
                            </el-radio-group>
                        </el-col>
                        <el-col :span="13" v-if="m2.type==0" style="line-height: 35px;">
                            考试：
                            <span v-if="!m2.isExamEdit">{{m2.examId>0?(m2.examName+"("+m2.examId+")"):"未选择"}}</span>
                            <el-button v-if="!m2.isExamEdit" type="text" size="mini" icon="search" @click='setEditble(m2, "isExamEdit", true)' style="margin-left: 30px;">更改</el-button>
                            <el-button v-if="!m2.isExamEdit && m2.examId>0" type="text" size="mini" icon="search" @click='showExamTopic(index, i2, m2)' style="margin-left: 30px;">选择题目</el-button>
                            <el-select v-if="m2.isExamEdit" v-model="m2.examId" filterable placeholder="请选择" style="width: 180px;" >
                                <el-option v-for="ex in examList" :key="ex.id" :label="ex.name+'(' + ex.id +')'" :value="ex.id"></el-option>
                            </el-select>
                            <el-button v-if="m2.isExamEdit" type="text" size="mini" icon="search" @click='examChange(m2, "isExamEdit", false, "examName", m2.examId)'>保存</el-button>
                        </el-col>
                        <el-col :span="2">
                            <el-button type="danger" size="mini" @click="deleteExam2(index, i2)">删除</el-button>
                        </el-col>
                    </el-row>
                    <el-row v-if="m2.type==0">
                        <el-col :span="24" v-if="'topic' in m2 && 'singleChoiceList' in m2.topic && m2.topic.singleChoiceList.length > 0">
                            <span>单选题：</span> <span v-for="tc in m2.topic.singleChoiceList">{{tc}},</span>
                        </el-col>
                        <el-col :span="24" v-if="'topic' in m2 && 'multiChoiceList' in m2.topic && m2.topic.multiChoiceList.length > 0">
                            <span>多选题：</span> <span v-for="tc in m2.topic.multiChoiceList">{{tc}},</span>
                        </el-col>
                        <el-col :span="24" v-if="'topic' in m2 && 'judgeList' in m2.topic && m2.topic.judgeList.length > 0">
                            <span>判断题：</span> <span v-for="tc in m2.topic.judgeList">{{tc}},</span>
                        </el-col>
                        <el-col :span="24" v-if="'topic' in m2 && 'zgList' in m2.topic && m2.topic.zgList.length > 0">
                            <span>主观题：</span> <span v-for="tc in m2.topic.zgList">{{tc}},</span>
                        </el-col>
                    </el-row>
                    <el-row v-if="m2.type==1">
                        <el-col :span="24">
                            <el-checkbox-group v-model="m2.scoreTypes" >
                                <el-checkbox v-for="st in scoreTypeList" :label="st.seq" :key="st.seq">{{st.name}}</el-checkbox>
                            </el-checkbox-group>
                        </el-col>
                    </el-row>

                </el-descriptions-item>

            </el-descriptions>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
        <examTopicIndexDialog ref="examTopicIndexDialog" @ok="examTopicIndexOK" :degreeInfo="selDegreeInfo"></examTopicIndexDialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'
    import examTopicIndexDialog from "./examTopicIndexDialog";
    import XLSX from "xlsx";

    export default {
        name: 'degreeDialog',
        data(){
            return {
                formLabelWidth: '100px', myTitle: null,
                dialogVisible:false, loading: false, selDegreeInfo: false,
                courseList:[], examList: [],
                degreeTypeList: [{name: 'KC', label: '课程达成度'}, {name: 'BY', label: '毕业达成度'}],
                degreeForm:{ id: null, licenceId: null,  name: null, degreeType: null, schoolId: null, teacherId: null, courseId: null, configContent: null,  },
                degreeRules: {
                    name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                    degreeType: [{ required: true, message: '请选择类型', trigger: 'blur' }],
                    configContent: [{ required: true, message: '请设置', trigger: 'blur' }],
                },
                scoreTypeList: [], scoreData: [],
                configContent: { normalCols: [], list: []},
            }
        },
        components:{ examTopicIndexDialog },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.loadExamList();
            this.loadCourseList();
	    },
        props: {
            degree: {
                type: Object
            }
        },
        watch: {
            degree: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        if(n.id != null){
                            console.log("n.id != null")
                            this.degreeForm = { id: n.id, licenceId: n.licence_id, name: n.name, degreeType: n.degree_type, schoolId: n.school_id,
                                courseId: n.course_id, configContent: n.config_content };
                            if(!XEUtils.isEmpty(n.config_content)) {
                                let configContent = JSON.parse(n.config_content);
                                console.log("parse", configContent);
                                this.scoreTypeList = configContent.normalCols;
                                this.configContent = configContent;
                            }
                            this.myTitle = '新建达成度';
                        }else{
                            this.myTitle = '编辑达成度';
                            this.degreeForm = { id: null, licenceId: null,  name: null, degreeType: null, schoolId: null, courseId: null, configContent: null,  };
                            this.configContent =  { normalCols: "", list: []};
                        }

                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            loadExamList(callback){

                let search = { licenceId: this.userInfo.licenceId, schoolId: null, teacherId: null};
                let role = this.userInfo.role;
                if(role == "sclAdmin") search["schoolId"] = this.userInfo.schoolId;
                if(role == "teacher") {
                    search["schoolId"] = this.userInfo.schoolId;
                    search["teacherId"] = this.userInfo.id;
                }
                let param = { controllerName: 'exam', methodName: '/examList', pageNo: 1, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.examList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            addTarget(){
                this.configContent.list.push({name: '课程目标'+(this.configContent.list.length+1), degreeValue: 0.7, isEdit:false,
                    list: [{name: "期中考试", isEdit:false, degreeValue: 0.7, type: 0, isExamEdit: false, examId: null, examName: null, topicIndexs: [], normalList: [], scoreTypes: [] },
                        {name: "期末考试", isEdit:false, degreeValue: 0.7, type: 0, isExamEdit: false, examId: null, examName: null, topicIndexs: [], normalList: [], scoreTypes: []}
                    ]})
            },
            addExam(idx){
                this.configContent.list[idx].list.push({name: "考试"+(this.configContent.list[idx].list.length+1), isEdit:false, degreeValue: 0.7, type: 0, isExamEdit: false,
                    examId: null, examName: null, topicIndexs: [], normalList: [], scoreTypes: []});
            },
            deleteExam(idx){
                this.configContent.list.splice(idx, 1);
            },
            deleteExam2(idx, i2){
                this.configContent.list[idx].list.splice(i2, 1);
            },
            typeChange(i1, i2){
                console.log("typeChange", i1, i2);
                let configContent = JSON.parse(JSON.stringify(this.configContent));
                this.$set(this, "configContent", configContent);
            },
            examChange(item, ableKey, albeV, key, selExamId){
                item[ableKey] = albeV;
                let examFind = XEUtils.find(this.examList, it => it.id == selExamId);
                if(examFind){
                    item[key] = examFind.name;
                }
                console.log("examChange", item, key, selExamId);
            },
            showExamTopic(index, i2, m2){

                let o = {index: index, i2: i2, m2: m2};
                this.selDegreeInfo = o;
                this.$refs.examTopicIndexDialog.showDialog();
            },
            examTopicIndexOK(i1, i2, o){
                this.configContent.list[i1].list[i2]["topic"] = o;
                this.configContent.list[i1].list[i2]["topicIndexs"] = [...o.singleChoiceList, ...o.multiChoiceList, ...o.judgeList, ...o.zgList];
                console.log("examTopicIndexOK", i1, i2, o, this.configContent);
                let configContent = JSON.parse(JSON.stringify(this.configContent));
                this.$set(this, "configContent", configContent);
            },
            httpRequest(e) {
                let file = e.file; // 文件信息
                if (!file) {
                    // 没有文件
                    return false;
                } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
                    // 格式根据自己需求定义
                    this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
                    return false;
                }
                this.scoreData = [];
                const fileReader = new FileReader();
                let this_ = this;
                fileReader.onload = ev => {
                    try {
                        const data = ev.target.result;
                        const workbook = XLSX.read(data, {
                            type: "binary" // 以字符编码的方式解析
                        });
                        // console.log(workbook)
                        const exlname = workbook.SheetNames[0]; // 取第一张表
                        let exlData = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
                        // console.log(exlData);
                        if(!exlData || exlData.length == 0){
                            this.$message.error("数据格式错误");
                            return false;
                        }
                        let first = exlData[0];
                        if('姓名' in first && '学号' in first){
                            this.clearScoreConfig();
                            this.readAndSetData(exlData);
                            return ;
                        }
                        this.$message.error("数据格式错误");
                        return false;

                    } catch (e) {
                        console.log(e);
                        console.log("出错了：：", e);
                        return false;
                    }
                };
                fileReader.readAsBinaryString(file);
            },
            clearScoreConfig(){
                let tarList = this.configContent.list;
                for (let i=0; i<tarList.length; i++){
                    let t = tarList[i];
                    let exList = t.list;
                    for (let j=0; j<exList.length; j++){
                        let e = exList[j];
                        if(e.type == 1){
                            e.scoreTypes = [];
                        }
                    }
                }
            },
            readAndSetData(exlData){
                let first = exlData[0];
                let col = [], scoreData = [];
                this.scoreData = [];
                let isName = false, isStudentNo = false;
                let seq = 1;
                for(let f in first){
                    if(f=='姓名'){isName = true; continue;}
                    if(f=='学号') {isStudentNo = true; continue;}
                    col.push({name:f, seq: seq});
                    seq ++;
                }
                for(let i=0; i<exlData.length;i++){
                    let d = exlData[i];

                    if("学号" in d && d["学号"] != null) {
                        // console.log("d", d);
                        let o = { studentNo: null, };
                        o.studentNo = ""+d["学号"];
                        for (let j=0; j<col.length; j++){
                            let colName = col[j].name;
                            if(colName in d && d[colName] != null) {
                                o["score"+col[j].seq] = d[colName];
                             }
                        }
                        scoreData.push(o);
                    }
                }
                console.log('readAndSetData', scoreData)
                this.scoreTypeList = col;
                this.configContent.normalCols = col;
                this.scoreData = scoreData;
            },

            validateConfig(){
                let list1 = this.configContent.list;
                if(list1.length < 1){
                    this.$alert('请添加目标', '提醒', {confirmButtonText: '确定',});
                    return false;
                }
                for (let i=0; i<list1.length; i++){
                    let lst = list1[i];
                    let list2 = lst.list;
                    if(list2.length < 1){
                        this.$alert('请添加考试', '提醒', {confirmButtonText: '确定',});
                        return false;
                    }
                    for (let j=0; j<list2.length; j++){
                        let ls2 = list2[j];
                        if(!XEUtils.isNumber(XEUtils.toNumber(ls2.degreeValue))){
                            this.$alert('系数必须是数字', '提醒', {confirmButtonText: '确定',});
                            return false;
                        }
                        if(ls2.type == 0 && ls2.examId == null){
                            this.$alert('请选择考试', '提醒', {confirmButtonText: '确定',});
                            return false;
                        }
                        if(ls2.type == 1 && ls2.scoreTypes.length < 1){
                            this.$alert('请选择平时成绩', '提醒', {confirmButtonText: '确定',});
                            return false;
                        }
                    }
                }
                return true;
            },
            submitForm(){
                console.log(this.degreeForm);
                let that = this;
                this.$refs.degreeDialogForm.validate((valid) => {
                    if(valid){
                        if(this.validateConfig()){
                            this.loading = true;
                            this.degreeForm.licenceId = this.userInfo.licenceId;
                            this.degreeForm.schoolId = this.userInfo.schoolId;
                            this.degreeForm.teacherId = this.userInfo.id;
                            this.degreeForm.configContent = JSON.stringify(this.configContent);
                            let myParam = JSON.parse(JSON.stringify(this.degreeForm));
                            myParam = Object.assign(myParam, {scoreData: this.scoreData});
                            let param = { controllerName: 'exam/degree', methodName: '/saveOrUpdate', param: myParam};

                            simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                                console.log('degree', code);
                                if (code == 0) {
                                    this.dialogVisible = false;
                                    that.$emit('ok');
                                }
                                this.loading = false;
                            }).catch((error) => {
                                this.loading = false;
                            });
                        }
                    }
                });
            },
            setEditble(item, key, v){
                item[key] = v;
            },
            degreeTypeChange(){
                if(this.degreeForm.degreeType == 'KC' && this.courseList.length == 0){
                    this.loadCourseList();
                }
            },
            loadCourseList(){

                let search = { licenceId: this.userInfo.licenceId, schoolId: this.userInfo.schoolId, orderBy: 'c.name'};
                let param = { controllerName: 'sclCource', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.courseList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

        },
    }
</script>

<style lang="scss" scoped type="text/scss" rel="stylesheet/scss">

    .degreeDialog{
        height: 800px;
        overflow-y: auto;
    }


</style>


