<template>
    <div class="degreeList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="名称或入学年"  @keyup.enter.native='loadDegreeList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='loadDegreeList'>查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='openDegree(null)'>添加</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>

                <el-table-column prop="name" label="名称"  width="150" align='center' ></el-table-column>
                <el-table-column prop="degree_type" label="类型" width="150"  align='center' :formatter="formatterType"></el-table-column>
                <el-table-column prop="usually_cou" label="上传学生数" width="100"  align='center' ></el-table-column>
                <el-table-column prop="config_content" label="描述" align='center' :formatter="formatterConfigContent" ></el-table-column>
                <el-table-column prop="operation" align='center' label="操作" width="300">
                    <template slot-scope='scope'>
                        <el-button  size="mini" @click='openDegree(scope.row)'>编辑</el-button>
                        <el-button type="success"  size="mini" @click='outputDegree(scope.row)'>生成</el-button>
                        <el-button type="danger" icon='edit' size="mini" @click='onDeleteDegree(scope.row)'>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>
        <degreeDialog ref="degreeDialog" :degree="selDegree" @ok="loadDegreeList"></degreeDialog>

    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import degreeDialog from "./degreeDialog";
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";
    import axios from "axios";
    import XLSX from "xlsx";


    export default {
        name: 'degreeList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:true, isShow:false,
                selDegree: null, selDegreeDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null, professionId: null},
            }
        },
        components:{
            degreeDialog, Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadDegreeList();
	   },
        methods: {

            loadDegreeList(){
                this.loading = true;

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    schoolId: this.userInfo.schoolId, };
                let param = { controllerName: 'exam/degree', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error", error)
                });
            },
            formatterType(row, column, cellValue, index){
                if(cellValue == 'KC') return '课程';
                if(cellValue == 'BY') return '毕业';
                return cellValue;
            },
            formatterConfigContent(row, column, cellValue, index){
                if(cellValue != null){
                    let s = "";
                    let p = JSON.parse(cellValue);
                    for(let i=0; i<p.list.length; i++){
                        let l = p.list[i];
                        s = s + (i+1) + '、' + l.name + "，考试：";
                        for (let j=0; j<l.list.length; j++){
                            let jl = l.list[j];
                            s = s + (j+1) + '、' + jl.name;
                            if(jl.type == 0){
                                s = s + "，类型：试卷, 名称：" + jl.examName;
                            }
                            if(jl.type == 1){
                                s = s + "，类型：平时成绩" ;
                            }
                            s = s + " \n " ;
                        }
                        s = s + " \n " ;
                    }
                    return s;
                }
                return cellValue;
            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadDegreeList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadDegreeList()
            },
            onDeleteDegree(row){
                let id = row.id
                let that = this;
                this.$confirm('此操作将删除达成度, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'exam/degree', methodName: '/delete', param: { id: id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadDegreeList()
                        }
                        if (code == -3) {
                            that.$message({ type: 'error', message: '该行政班下已有数据，请先清除数据后再删除！'});
                            return ;
                        }
                    });
                }).catch(() => {

                });
            },
            openDegree(row){
                if(row != null){
                    this.selDegree = row;
                }else{
                    this.selDegree = { id: null, time: (new Date()).getMilliseconds()};
                }
                this.$refs.degreeDialog.showDialog();
            },
            async outputDegree(row){
                let p = JSON.parse(row.config_content);
                let deg = [], head1 = ['班级', '学号', '姓名'], head2 = ['', '', ''], head3 = ['', '', ''], head4 = ['', '', ''];
                let minDcd = ['达成度最小值', '', ''], maxDcd = ['达成度最大值', '', ''], avgDcd = ['平均值', '', ''];
                let mergeRule = [];
                for(let i=0; i<p.list.length; i++){
                    let l = p.list[i];
                    head1.push(l.name);
                    mergeRule.push({s: {r: 0, c: head1.length-1}, e: {r: 0, c: head1.length+l.list.length-1}});
                    let dcdValue = [];
                    for (let j=0; j<l.list.length; j++){
                        let jl = l.list[j];
                        head2.push(jl.name);
                        if(j>0) head1.push('');
                        dcdValue.push(jl.degreeValue);
                        if(jl.type == 0){
                            let item = {examId: jl.examId, examName: jl.examName, i: i, j: j, topicIndexs: jl.topicIndexs, minScore: 0, maxScore: 0, avgScore: 0, fullScore: 0, list: []};
                            await this.loadExamStudent(deg, item);
                            head3.push(jl.topicIndexs.join(","));
                            head4.push(item.fullScore);
                            avgDcd.push(item.avgScore);
                        }
                        if(jl.type == 1){
                            let item = { minScore: 0, maxScore: 0, avgScore: 0, fullScore: 0};
                            await this.loadDegreeUsuallyList(row.id, deg, jl.scoreTypes, item);
                            head3.push("");
                            head4.push("");
                            avgDcd.push(item.avgScore);
                        }
                        minDcd.push(""); maxDcd.push("");
                    }
                    if(dcdValue.length > 0){
                        let dcdLen = dcdValue.length;
                        head1.push(""); head2.push("达成度"+(i+1)); head3.push(""); head4.push("");
                        console.log("dcdValue", dcdLen, dcdValue);
                        let minD = 100, maxD = 0, avgD = 0;
                        for (let j=0; j<deg.length; j++){
                            let d = deg[j];
                            let dLen = d.length;
                            let dcdSum = 0;
                            for (let i=0; i<dcdValue.length; i++){
                                let dv = dcdValue[i];
                                if(d[dLen-dcdLen+i] != ""){
                                    dcdSum = dcdSum + parseFloat(d[dLen-dcdLen+i]) * dv;
                                    console.log("d", dLen,dcdLen,i, d[dLen-dcdLen+i], dcdSum);
                                }
                            }
                            d.push(dcdSum);
                            if(minD > dcdSum) minD = dcdSum;
                            if(maxD < dcdSum) maxD = dcdSum;
                            avgD = avgD + dcdSum;
                        }
                        avgD = XEUtils.round(avgD / deg.length, 3);
                        minDcd.push(minD); maxDcd.push(maxD); avgDcd.push(avgD);
                    }
                }

                for (let i=1; i<4; i++){
                    mergeRule.push({s: {r: i, c: 0}, e: {r: i, c: 2}});
                }
                deg.unshift(head1, head2, head3, head4);
                deg.push(minDcd, maxDcd, avgDcd);
                this.outputXlsxFile(row.name, deg, mergeRule);
            },
            async loadExamStudent(deg, item){
                let that = this;
                axios.defaults.headers = {'Content-Type': 'application/json;charset=UTF-8'}
                await axios({
                    method:'get',
                    url: window.g.OSS_HOST+"answer/analyse/"+item.examId+"/exam_student_"+item.examId+".json",
                }).then((res)=>{
                    let data = res.data;
                    let topicIndexs = item.topicIndexs;
                    let avgScore = 0;
                    if(data != null && data.length > 0){
                        for (let i=0; i<data.length; i++){
                            let d = data[i];
                            let tiList = d.ti_list;
                            let stuScore = 0, rightScore = 0;
                            for (let j=0; j<topicIndexs.length; j++){
                                let tf = XEUtils.find(tiList, item => item.ix == topicIndexs[j]);
                                if(tf){
                                    stuScore = stuScore + tf.us;
                                    rightScore = rightScore + tf.rs;
                                }
                            }
                            avgScore = avgScore + stuScore;
                            if(i == 0) item.fullScore = rightScore;
                            let fidx = XEUtils.findIndexOf(deg, item => (item.length > 1 && item[1] == d.student_no));
                            if(fidx > -1){
                                deg[fidx].push(stuScore);
                            }else{
                                deg.push([d.hard_name, d.student_no, d.student_name, stuScore]);
                            }
                        }
                        avgScore = XEUtils.round(avgScore / data.length, 2);
                        item.avgScore = avgScore;
                    }

                }).catch((error) => {
                    that.loading = false;
                });
            },
            async loadDegreeUsuallyList(id, deg, scoreTypes, item){

                let search = { degreeId: id, orderBy: 'hard_class_id' };
                let param = { controllerName: 'exam/degree/usually', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                await simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let tableData = data.result;
                        let avgScore = 0;
                        if(tableData && tableData.length > 0){
                            let scoreType = scoreTypes[0];
                            for (let i=0; i<tableData.length; i++){
                                let d = tableData[i];
                                let fidx = XEUtils.findIndexOf(deg, item => (item.length > 1 && item[1] == d.student_no));
                                let key = "score"+scoreType;
                                if(fidx > -1){
                                    deg[fidx].push(d[key]);
                                }else{
                                    deg.push([d.hard_name, d.student_no, d.student_name, d[key]]);
                                }
                                avgScore = avgScore + d[key];
                                // console.log(d.hard_name, d.student_no, d.student_name);
                            }
                            avgScore = XEUtils.round(avgScore / tableData.length, 2);
                            item.avgScore = avgScore;
                        }
                    }
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            outputXlsxFile(degreeName, data, mergeRule) {
                console.log(data)
                let sheetNames = ['达成度'];
                let sheetsList = {};
                let wb = XLSX.utils.book_new();
                sheetsList['达成度'] = XLSX.utils.aoa_to_sheet(data);
                sheetsList['达成度']['!merges'] = mergeRule;
                console.log("sheetNames", sheetNames)
                wb["SheetNames"] = sheetNames;
                wb["Sheets"] = sheetsList;

                XLSX.writeFile(wb, "达成度_"+degreeName+".xlsx");
            },
        },
    }
</script>

<style lang="scss" scoped type="text/scss" rel="stylesheet/scss">

    .degreeList {

        .search_container{

        }
        .btnRight{
            float: right;
            margin-right: 0px !important;
        }
        .searchArea{
            background:rgba(255,255,255,1);
            border-radius:2px;
            padding: 18px 18px 0;
        }
        .table_container{
            padding: 10px;
            background: #fff;
            border-radius: 2px;
        }
        .el-dialog--small{
           width: 600px !important;
        }
        .el-table .cell{
            white-space: pre-line;
        }
        .pagination{
            text-align: left;
            margin-top: 10px;
        }
    }

</style>


