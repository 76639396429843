<template>

    <div class="examTopicIndexDialog" >
        <el-dialog title="选择题目" width="60%"  :close-on-click-modal="false" :visible.sync="dialogVisible">

            <el-descriptions class="margin-top" :title="examName" :column="1"  border>

                <el-descriptions-item v-if="singleChoiceList.length > 0" >
                    <template slot="label">
                        单选题
                        <el-checkbox :indeterminate="isSingleChoice" v-model="singleChoiceCheckAll" @change="handleCheckAllChange(1)">全选</el-checkbox>
                    </template>
                    <el-checkbox-group v-model="checkedSingleChoices" >
                        <el-checkbox v-for="si in singleChoiceList" :label="si.topic_index" :key="si.topic_index">{{si.topic_index+"("+si.score+")"}}</el-checkbox>
                    </el-checkbox-group>
                </el-descriptions-item>

                <el-descriptions-item v-if="multiChoiceList.length > 0">
                    <template slot="label">
                        多选题
                        <el-checkbox :indeterminate="isMultiChoice" v-model="multiChoiceCheckAll" @change="handleCheckAllChange(2)">全选</el-checkbox>
                    </template>
                    <el-checkbox-group v-model="checkedMultiChoices" >
                        <el-checkbox v-for="si in multiChoiceList" :label="si.topic_index" :key="si.topic_index">{{si.topic_index+"("+si.score+")"}}</el-checkbox>
                    </el-checkbox-group>
                </el-descriptions-item>

                <el-descriptions-item v-if="judgeList.length > 0" >
                    <template slot="label">
                        判断题
                        <el-checkbox :indeterminate="isJudge" v-model="judgeCheckAll" @change="handleCheckAllChange(3)">全选</el-checkbox>
                    </template>
                    <el-checkbox-group v-model="checkedJudges" >
                        <el-checkbox v-for="si in judgeList" :label="si.topic_index" :key="si.topic_index">{{si.topic_index+"("+si.score+")"}}</el-checkbox>
                    </el-checkbox-group>
                </el-descriptions-item>

                <el-descriptions-item v-if="zgList.length > 0" >
                    <template slot="label">
                        主观题
                        <el-checkbox :indeterminate="isZg" v-model="zgCheckAll" @change="handleCheckAllChange(4)">全选</el-checkbox>
                    </template>
                    <el-checkbox-group v-model="checkedZgs" >
                        <el-checkbox v-for="si in zgList" :label="si.topic_index" :key="si.topic_index">{{si.topic_index+"("+si.score+")"}}</el-checkbox>
                    </el-checkbox-group>
                </el-descriptions-item>

            </el-descriptions>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script>

  import {mapState, mapActions} from 'vuex'
  import * as simpleApi from "request/simple";

  export default {
    data(){
      return {
          dialogVisible:false, loading: false,
          topicList: [], examId: null, examName: null, i1: null, i2: null, m2: null,
          singleChoiceList: [], multiChoiceList: [], judgeList: [], zgList: [],
          checkedSingleChoices: [], checkedMultiChoices: [], checkedJudges: [], checkedZgs: [],
          isSingleChoice: true, isMultiChoice: true, isJudge: true, isZg: true,
          singleChoiceCheckAll: true, multiChoiceCheckAll: true, judgeCheckAll: true, zgCheckAll: true
      }
    },
    components: {

    },
    computed:{


    },
      props: {
          degreeInfo: {type: Object},

      },
      watch: {
          degreeInfo: {
              deep: true, immediate:true,
              handler(n, o){
                  console.log(n)
                  if(n){
                      this.i1 = n.index;
                      this.i2 = n.i2;
                      this.m2 = n.m2;
                      this.examId = n.m2.examId;
                      this.examName = n.m2.examName;
                      if(n.m2.topic != null){
                          if(n.m2.topic.singleChoiceList != null)  this.checkedSingleChoices = n.m2.topic.singleChoiceList; else this.checkedSingleChoices = [];
                          if(n.m2.topic.multiChoiceList != null)  this.checkedMultiChoices = n.m2.topic.multiChoiceList; else this.checkedMultiChoices = [];
                          if(n.m2.topic.judgeList != null)  this.checkedJudges = n.m2.topic.judgeList; else this.checkedJudges = [];
                          if(n.m2.topic.zgList != null)  this.checkedZgs = n.m2.topic.zgList; else this.checkedZgs = [];
                      }else{
                          this.checkedSingleChoices = []; this.checkedMultiChoices = []; this.checkedJudges = []; this.checkedZgs = [];
                      }
                      this.loadTopicList();
                  }
              }
          },
      },
    created(){
    },
    mounted(){


    },
    methods: {
        showDialog(){
            this.dialogVisible = true;
        },
        hideDialog(){
            this.dialogVisible = false;
        },
        cancel(){
            this.dialogVisible = false;
            this.$emit('cancel');
        },
        submitForm(){
            let o = {singleChoiceList: this.checkedSingleChoices,  multiChoiceList: this.checkedMultiChoices,
                judgeList: this.checkedJudges, zgList: this.checkedZgs}
            this.$emit('ok', this.i1, this.i2, o);
            this.dialogVisible = false;
        },
        loadTopicList(callback){
            if(this.examId == null) return ;
            let search = { examId: this.examId, orderBy:  'topic_index'};
            let param = { controllerName: 'akExamTopic', methodName: '/list', pageNo: 0, pageSize: 5000, param: search};
            return simpleApi.list(param).then(({result: {code, data}}) =>  {
                console.log("loadTopicList", data);
                if (data.code == 0) {
                    let list = data.result;
                    let judgeList = [], zgList = [], singleChoiceList =[], multiChoiceList = [];
                    for (let i=0; i<list.length; i++){
                        let d = list[i];
                        if(d.topic_type2 == 'Object'){

                            if(d.topic_type == 'SingleChoice') singleChoiceList.push(d);
                            if(d.topic_type == 'MultiChoice'){
                                multiChoiceList.push(d);
                            }
                            if(d.topic_type == 'Judge') judgeList.push(d);
                        }else{
                            zgList.push(d);
                        }
                    }
                    this.topicList = list;
                    this.singleChoiceList = singleChoiceList;
                    this.multiChoiceList = multiChoiceList;
                    this.judgeList = judgeList;
                    this.zgList = zgList;
                    if(callback) callback();
                }
            }).catch((error) => {
                console.log("error")
            });
        },
        handleCheckAllChange(v) {
            if(v == 1){
                if(this.singleChoiceCheckAll){
                    let allList = [];
                    for (let i=0; i<this.singleChoiceList.length; i++){
                        let d = this.singleChoiceList[i];
                        allList.push(d.topic_index);
                    }
                    this.checkedSingleChoices = allList;
                }else this.checkedSingleChoices = [];
                this.isSingleChoice = false;
            }
            if(v == 2){
                if(this.multiChoiceCheckAll){
                    let allList = [];
                    for (let i=0; i<this.multiChoiceList.length; i++){
                        let d = this.multiChoiceList[i];
                        allList.push(d.topic_index);
                    }
                    this.checkedMultiChoices = allList;
                }else this.checkedMultiChoices = [];
                this.isMultiChoice = false;
            }
            if(v == 3){
                if(this.judgeCheckAll){
                    let allList = [];
                    for (let i=0; i<this.judgeList.length; i++){
                        let d = this.judgeList[i];
                        allList.push(d.topic_index);
                    }
                    this.checkedJudges = allList;
                }else this.checkedJudges = [];
                this.isJudge = false;
            }
            if(v == 4){
                if(this.zgCheckAll){
                    let allList = [];
                    for (let i=0; i<this.zgList.length; i++){
                        let d = this.zgList[i];
                        allList.push(d.topic_index);
                    }
                    this.checkedZgs = allList;
                }else this.checkedZgs = [];
                this.isZg = false;
            }
        },
    }
  }
</script>

<style lang="scss" scoped type="text/scss" rel="stylesheet/scss">
  .examTopicIndexDialog{
    border-radius: 50%;
      width: 100px;
      height: 100px;
      .valueRow {
        font-size: 18px;
      }
      .labelRow {
          font-size: 16px;
      }
  }

</style>
