import { render, staticRenderFns } from "./degreeDialog.vue?vue&type=template&id=3d212f5d&scoped=true&"
import script from "./degreeDialog.vue?vue&type=script&lang=js&"
export * from "./degreeDialog.vue?vue&type=script&lang=js&"
import style0 from "./degreeDialog.vue?vue&type=style&index=0&id=3d212f5d&lang=scss&scoped=true&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d212f5d",
  null
  
)

export default component.exports